import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "../components/layout/GlobalStyles"
import { Seo } from "../components/Seo"

const StyledPrivacyPolicy = styled.section`
  background: ${colors.primary1};
  padding: 85px 0px 72px;
  h1 {
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    margin: 0;
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 15px;
  }
  a {
    color: ${colors.primary2};
    &:hover {
      text-decoration: underline;
    }
  }
  h2 {
    color: ${colors.primary2};
    margin-top: 32px;
  }
  h3 {
    margin-top: 32px;
    color: ${colors.dark3};
    margin-bottom: 5px;
  }
  h2,
  h3 {
    font-family: ${fonts.primary};

    font-weight: 900;
  }
  span,
  p {
    margin: 15px 0px 0px 0px;
    font-weight: 400;
    font-family: ${fonts.primary};
    color: ${colors.dark3};
  }
  @media ${device.laptop} {
    padding: 168px 0px 128px;
  }
`

const PrivacyPolicy = () => {
  return (
    <>
      <Seo
        title="MLIFE | Privacy Policy"
        description="Our Privacy Policy, together with our Terms of Use, constitutes a binding agreement between us and you."
      />{" "}
      <StyledPrivacyPolicy>
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>
            Our Privacy Policy, together with our Terms of Use, constitutes a
            binding agreement between us and you, when you visit our Site,
            mlifefoundation.org, or participate in our events, activities, and
            programs that requires us to collect your personal identifiable
            data. Our Privacy Policy outlines our practices and contains no
            promises or representations other than those contained herein and/or
            implied by law.
          </p>

          <p>
            Our Privacy Policy is intended to communicate to you what personal
            identifiable data we collect, how we collect it, share, store, and
            safeguard your data, and your right to change, edit, or request the
            removal of your data from our Site or records.
          </p>

          <p>
            We request that you do not continue to use our Site or provide
            personal identifiable data if you do not agree with our Terms of Use
            and all the terms outlined in our Privacy Policy. Your use of our
            Site or giving us of your personal identifiable data indicate your
            agreement to our Privacy Policy and our Terms of Use.
          </p>

          <p>
            Please follow this link to familiarize yourself with technology
            terms such as &ldquo;cookies.&rdquo; For any questions about our
            Privacy Policy, please contact us by email at
            privacy@mlifefoundation.org, you can also contact us via mail:
          </p>

          <p style={{ marginTop: "25px", fontWeight: "700" }}>
            <bold>
              Attn: Privacy Policy MLIFE Foundation 1909 Mission Street San
              Francisco, CA 94103
            </bold>
          </p>

          <h2>What does personal identifiable data mean?</h2>

          <p>
            Personal identifiable data is non-public information that personally
            identifies you. This includes your name, address, email address, and
            phone number, as well as sensitive information such as your
            government issued identity card and/or bank account. It may also
            include information such as, but not limited to, your social
            security number, sexual orientation, gender, gender preferences
            and/or identity, religious views and the instructions you give us
            about how we communicate with you.
          </p>

          <h2>How do we collect information about you?</h2>

          <h3>Information you provide to us</h3>

          <p>
            We collect personal information you give to us directly. For
            example, we collect it:
          </p>

          <p>
            when you talk to us in phone calls or in meetings with us either
            individually or during events. when you register for an account at
            our Site or participate in our worship, training, outreach and/or
            inter-cultural, cross-tribal exchanges and activities. when you
            apply for emergency grant, assistance, and/or to participate in our
            activities. when you make prayer and service requests. when you
            access mlifefoundation.org or any of our Sites, networks or apps
            through your desktop, tablet, or phone. The information we collect
            from you this way can vary widely, from information about your
            current residence, immigration status, sexual orientation and
            identity, gender preferences to a new e-mail address.
          </p>

          <p>
            Information we collect automatically when you use our online
            servicess
          </p>

          <p>
            We may automatically record certain information when you use our
            online services through your desktop, laptop, tablet, or phone. This
            may include your:
          </p>

          <p>
            IP address or other device address or ID web browser and/or device
            type the features of the apps you use the web pages or sites that
            you visit just before or just after you use our services the pages
            or other content you view or otherwise interact with on our services
            the dates and times that you visit, access, or use our services We
            may also collect information about your interactions with our email
            messages, such as whether you opened, clicked on, or forwarded a
            message. We provide more information below about how we use
            technologies to automatically collect information.
          </p>

          <h3>Information we collect from other sources</h3>

          <p>
            We may collect personal and non-personal information from third
            parties such as partner organizations. For example, we may request
            that you authorize an exchange of information between us and other
            governmental or nongovernmental agencies in order to resolve or
            address your needs. In that case, we may find personal identifying
            information such marital status, income level, education, etc.
          </p>

          <p>
            We may also collect information about you from your website, social
            media pages, or from online reviews of your work. If we combine or
            associate information from other sources with personal information,
            we collect from you, we will treat the combined information as
            personal information and treat it as described in our privacy
            policy.
          </p>

          <h2>How do we use personal information we collect?</h2>

          <p>
            We use personal information to provide better services to you and to
            improve our operations.
          </p>

          <h3>For service reasons</h3>

          <h3>We use personal information to:</h3>

          <p>
            provide you with services respond to your requests and questions
            verify your identity, including during account creation and password
            reset processes verify information with third parties for accuracy
            process your donation resolve disputes, collect fees, and
            troubleshot problems provide information about our pre-approved and
            other promotional offers provide information about produces and
            services offered by third parties For learning about our users
          </p>

          <p>
            We use automatically-collected information to learn about usage
            trends and preferences of our users. This helps us:
          </p>

          <p>
            Personalize our services Personalize our communications Keep track
            of how users provide information to us online Monitor the use of our
            online services generally, such as the number of users and what
            pages they check, and how they respond to promotions Develop new
            services, resources, and products Generally, do a better job for our
            users We gather automatically-collected information from all our
            online users, not just you.
          </p>

          <h3>When do we share your information?</h3>

          <p>
            We do not share your personal information with organizations or
            individuals except as follows:
          </p>

          <h3>With your consent</h3>

          <p>
            We may share personal information when we have your consent to do
            so. Please note that we do not sell, rent, or otherwise share
            personal information with third parities for their direct marketing
            purposes without your consent.
          </p>

          <h3>For service reasons</h3>

          <p>
            We may share personal information with third parties that provide
            website, application development, hosting, maintenance, payment, and
            other services for us.
          </p>

          <h3>For legal reasons</h3>

          <p>We may share personal information with:</p>

          <p>
            Law enforcement agencies, courts, or others when legally required,
            such as under a subpoena, court order, bankruptcy action, or other
            legal process, or to cooperate with law enforcement or other
            governmental officials. Third parties when we believe it is
            appropriate to take precautions against liability, protect ourselves
            against unlawful activity, investigate or defend against third-party
            claims, protect the security and integrity of our operations,
            protect our property, enforce our legal rights, or protect the
            safety and rights of others Acquirers or successors as part of a
            merger, sale of assets, or similar transaction to which we are party
            (should this occur, we will continue to ensure that these privacy
            policies apply to personal information) Generally, we limit the
            information we share to that which is reasonably necessary for these
            third parties to perform specific functions.
          </p>

          <h3>Non-personal information</h3>

          <p>
            We do not consider personal information to include information that
            does not identify a specific user. This includes
            automatically-collected, aggregated, or other information that is
            anonymous. We may make this information available to third parties.
            For example, we may provide it when we comply with reporting
            obligations, carry out marketing activities, or study users&rsquo;
            interests, habits and usage patterns.
          </p>

          <p>
            Can you make changes in how we collect and use information and how
            we communicate with you?
          </p>

          <p>
            There&rsquo;re a variety of ways for you to change how we collect
            and use your information, and how we communicate with you. As
            described below, you may:
          </p>

          <p>
            Choose not to provide us with information Update your information
            Opt-out of one or more forms of communication from us Limit how we
            collect information automatically Disable location services on your
            mobile device Here is more information about your choices:
          </p>

          <p>Not providing information</p>

          <p>
            You may choose not to share certain personal information with us.
            That is entirely up to you. Not sharing some information may mean
            that we will not be able to provide you with some services,
            products, and online capabilities.
          </p>

          <h3>Updating your information</h3>

          <p>
            We want to keep your personal information accurate and up to date.
            If you have any questions about what information we have about you,
            or you wish to access or change any personal information, you may
            contact us at: privacy@mlifefoundation.org Please note that your
            ability to change certain information may depend on your account
            status and type. We&rsquo;ll let you know about that when we respond
            to your request.
          </p>

          <p>
            Opting out of marketing emails, texts, mail, phone calls or faxes
          </p>

          <p>
            We may send marketing communications to you in a variety of ways,
            including emails, texts, regular mail, phone calls, and faxes. If
            you do not wish to receive one or more of these types of
            communications from us, you may instruct us by visiting our
            Communication Preference Center and updating your preference or by
            following directions that may be provided in the communication.
          </p>

          <p>
            Please be aware that if you opt out of receiving email from us or
            otherwise modify the nature or frequency of marketing communications
            you receive from us, it may take up to 10 business days for us to
            process your request. In addition, even after you opt out from
            receiving commercial messages, you will continue to receive
            administrative messages from us.
          </p>

          <h3>Changing how information is collected automatically</h3>

          <p>
            As discussed later in this policy, you can adjust in the settings of
            the web browsers on your device to modify your cookie settings. This
            affects how information is collected automatically when you use your
            device
          </p>

          <h3>Turning on location services on your mobile device</h3>

          <p>
            When you download or use our mobile applications, or access one of
            our mobile optimized sites, we may receive information about your
            location and your mobile device, including a unique identifier for
            your device. We may use this information to provide you with
            location-based services, such as advertising, search results, and
            other personalized content.
          </p>

          <p>
            Most mobile devices allow you to control or disable location
            services on the device&rsquo;s setting menu. If you have questions
            about how to disable your device&rsquo;s location services, we
            recommend you contact your mobile service carrier or the
            manufacturer of your device.
          </p>

          <h3>Using Do-Not-Track signals</h3>

          <p>
            We do not specifically respond to Do-Not-Track (&ldquo;DNT&rdquo;)
            signals. Some third-party websites keep track of your browsing
            activities, including across other websites on the Internet, which
            enables these websites to tailor what they present to you. Your
            browser may allow you to set a DNT signal on your browser so that
            these third parties know you do not want to be tracked
          </p>

          <h2>How do we protect your personal information?</h2>

          <p>
            We use physical, administrative, and technical safeguards to protect
            personal information:
          </p>

          <p>
            We store and process personal information on our computers in North
            America where our facilities are located. We use safeguards such as
            firewalls, data encryption, physical access controls to our
            computers, and authorization controls for information access We
            review and update our information collection, storage, and
            processing practices, including physical security measures, to help
            guard against unauthorized access We cannot, however, ensure or
            warrant the security of any personal Information, and you provide
            information to us with that understanding. We are not responsible
            for circumvention of any privacy settings or security measures on
            our services. Even after information posted on our websites is
            removed, caching and archiving services may have saved that
            information, and other users or third parties may have copied or
            stored the information.
          </p>

          <p>
            If we learn of a security systems breach, then we may attempt to
            notify you so that you can take appropriate protective steps. We may
            also post a notice on our websites and/or email you if a security
            breach occurs.
          </p>

          <h3>How do we use cookies and similar technologies?</h3>

          <h3>Cookies</h3>

          <p>
            When you use our websites, we may send one or more cookies to your
            device. Cookies can be used to follow your activity on the website.
            That information helps us to understand your preferences and improve
            your website experience. Please review your web browser
            &ldquo;Help&quot; file to learn the proper way to modify your cookie
            settings. Please note that if you delete, or choose not to accept,
            cookies from our websites, you may not be able to use the features
            of our websites to their fullest potential.
          </p>

          <h3>Third-Party Web Beacons</h3>

          <p>
            We may use third-party content or advertising on our websites that
            use various forms of web beacons, which allow the third-party
            content provider to read and write cookies to your browser in
            connection with your viewing of that content
          </p>

          <h3>Third-Party Buttons</h3>

          <p>
            We may use third-party buttons (such as Facebook &ldquo;like&rdquo;
            or &ldquo;share&rdquo; buttons) that allow third parties to collect
            information about you through their browser cookies, even when you
            do not interact with the button. Information collected through web
            beacons and buttons is collected directly by these third parties.
            Client does not participate in that data transmission. Information
            collected by a third party in this manner is subject to that third
            party&rsquo;s own data collection, use, and disclosure policies.
          </p>

          <h2>When does this policy apply?</h2>

          <p>
            This policy applies to your relationship with MLIFE and its
            affiliates, if any, including both your personal and online
            interactions with us. This policy does not apply to services offered
            by other nonprofits, businesses, or individuals, including other
            sites linked to our sites or that you hear about from us.
            Information you provide to third parties is provided directly to
            them, not us. It is subject to their own policies, if any, governing
            privacy and security, even if accessed through our sites.
          </p>

          <p>
            For clarity, we are not responsible for the content or privacy and
            security practices and policies of those third-party sites or
            services, or for their products or services. We encourage you to
            learn about third parties&rsquo; privacy and security policies
            before providing them with information.
          </p>

          <h2>Additional Terms</h2>

          <h3>Children&rsquo;s privacy</h3>

          <p>
            We do not want to collect information from children. Do not provide
            any personal information unless you are at least 13 years of age.
            Please caution your children not to provide any information unless
            you consent to their participation in our programs and activities.
            If a child under 13 has provided personal information, a parent or
            guardian may alert us at privacy@mlifefoundation.org and we will
            subject to applicable law and other provisions of this policy, use
            commercially reasonable efforts to delete it from our database.
          </p>

          <h3>International visitors</h3>

          <p>
            If you use our services from the European Union or other regions of
            the world with laws governing data collection and use that may
            differ from U.S. law, you should know you are transferring your
            personal information outside of those regions to the United States
            for storage and processing.
          </p>

          <h2>Changes and updates to this policy</h2>

          <p>
            We may change this policy at any time. For example, as technologies,
            laws, or user needs change, our websites and its features may also
            change. We will communicate these changes to you by posting them on
            our websites and/or by notifying you through email.
          </p>

          <p>Terms and conditions</p>

          <p>
            This policy is part of and incorporated into the MLIFE Terms of
            Service, which is the contract between you and MLIFE governing use
            of this site. Parts of the Terms of Service affect this policy, so,
            unless you have already done so, please review them here. Terms used
            but not defined in this policy have the definitions in the Terms of
            Service.
          </p>

          <p>Your California privacy rights</p>

          <p>
            Under California Civil Code Section 1798.83, California customers
            are entitled to request information relating to whether a business
            has disclosed personal information to any third parties for the
            third parties&rsquo; direct marketing purposes. As explained
            elsewhere in this policy, MLIFE will not sell or transfer your
            personal information to third parties for their direct marketing
            purposes without your consent.
          </p>

          <h2>Other laws</h2>

          <p>
            This policy is intended to supplement any federal and state laws
            applicable to us relating to the privacy of information we obtain to
            provide education services, and perform other nonprofit work.
          </p>
        </div>
      </StyledPrivacyPolicy>
    </>
  )
}

export default PrivacyPolicy
